import { useParams } from 'react-router-dom';
import './ClaimPage.scss';
import { useEffect, useState } from 'react';
import { Claim } from '../../types';
import { getClaim, getVote, postVote } from '../../api';
import BountyBar from './BountyBar';
import { useAppContext } from '../../contexts/AppContext';
import ClaimBar from './ClaimBar';
import BountyFeed from './BountyFeed';
import HunterBar from './HunterBar';
import { Button } from '@mui/material';
import MediaViewer from '../MediaViewer';
import { LoadingButton } from '@mui/lab';
import ScrollToTop from '../ScrollToTop';
import FundDialog from './FundDialog';

export default function ClaimPage() {
  const { id } = useParams();
  const { snackError } = useAppContext();
  const [loadCount, setLoadCount] = useState(0);
  const [claim, setClaim] = useState({ } as Claim);
  const [voted, setVoted] = useState(0);
  const [fundOpen, setFundOpen] = useState(false);

  useEffect(() => {
    setLoadCount(value => value + 1);
  }, [id]);

  useEffect(() => {
    if (id && loadCount) {
      getClaim(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading claim: " + result.status);
          console.error('getClaim: %d', result.status);
        } else {
          setClaim(result.data);
        }
      });
      getVote(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading vote: " + result.status);
          console.error('getVote: %d', result.status);
        } else {
          setVoted(result.data._id ? 2 : 1);
        }
      });
    }
  }, [loadCount]);

  async function voteClicked() {
    const result = await postVote(claim._id);
    if (result.status !== 204) {
      snackError("Error posting vote: " + result.status);
      console.error("postVote: " + result.status);
    } else {
      setLoadCount(value => value + 1);
      setVoted(2);
    }
  }
  
  return (
    <div className='claim-page'>
      <ScrollToTop />
      <div className='claim-box'>
        <BountyBar bounty={claim.bounty} />
        <MediaViewer file={claim.file} />
        <ClaimBar claim={claim} />
        <div className='claim-actions'>
          <LoadingButton 
            variant="contained"
            loading={voted === 0}
            disabled={voted === 2}
            onClick={voteClicked}>
            { voted === 2 ? "Voted" : "Vote"}
          </LoadingButton>
          <Button variant="contained" onClick={() => setFundOpen(true)}>
            Fund
          </Button>
        </div>
      </div>
      <HunterBar hunter={claim.hunter} />
      { claim.bountyId && <BountyFeed search={claim.bounty.title} claimId={claim._id} excludeId={claim.bountyId} /> }
      <FundDialog open={fundOpen} setOpen={setFundOpen} claim={claim} />
    </div>
  );
}