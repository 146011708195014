import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d83e86',
    },
    secondary: {
      main: '#ffc947',
    },
    info: {
      main: '#08bce3',
    },
    error: {
      main: '#fe003f',
    },
    success: {
      main: '#e3ed44',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#FFF',
      secondary: '#CCC',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(5px)'
          },
        },
      },
      styleOverrides: {
        paper: {
          backgroundColor: '#161618',
        },
      },
    },
  }
});

export default theme;
