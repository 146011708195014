const devConfig = {
  apiUrl: "http://localhost:3005",
  mediaUrl: "https://bounty.sfo2.cdn.digitaloceanspaces.com/",
}

const prodConfig = {
  apiUrl: "https://seahorse-app-6765g.ondigitalocean.app",
  mediaUrl: "https://bounty.sfo2.cdn.digitaloceanspaces.com/",
}

function loadAppConfig() {
  let url = window.location.origin;
  if (url.includes("bounty.fan")) {
    return prodConfig;
  }
  return devConfig;
}

export const appConfig = loadAppConfig();