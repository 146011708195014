
export function formatMoney(value: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value / 800.0);
}

export function formatPostDate(utcDate: Date): string {
  const date = new Date(utcDate); // Converts UTC to local timezone
  const minutes = Math.ceil((new Date().getTime() - date.getTime()) / 60000);
  const hours = Math.ceil(minutes / 60);
  const days = Math.ceil(hours / 24);
  if (minutes > 0) {
    if (minutes < 60) {
      if (minutes === 1) return '1 minute ago';
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      if (hours === 1) return '1 hour ago';
      return `${hours} hours ago`;
    } else if (days < 10) {
      if (days === 1) return '1 day ago';
      return `${days} days ago`;
    } else if (days < 365) {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }
  }
  return date.toLocaleDateString(undefined, { year: '2-digit', month: '2-digit', day: 'numeric' });
}

export function countdownProgress(startDate: Date, endDate: Date): number {

  if (startDate && endDate) {
    const currTime = new Date().getTime();
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();
    const total = endTime - startTime;
    const progress = currTime - startTime;
    if (total > 0 && progress > 0) {
      if (progress >= total) return 100;
      return progress * 100 / total;
    }
  }
  return 0;
}

export function bountyActive(targetDate: Date): boolean {
  if (targetDate) {
    const date = new Date(targetDate);
    let diff = date.getTime() - new Date().getTime();
    return diff >= 0;
  }
  return false;
}

export function formatCountdown(targetDate: Date, short: boolean): string {
  if (targetDate) {
    const date = new Date(targetDate);
    let diff = date.getTime() - new Date().getTime();

    const past = diff < 0;
    diff = Math.abs(diff);

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(diff / (1000 * 60));
    diff -= minutes * (1000 * 60);

    const seconds = Math.floor(diff / 1000);

    if (short) {
      if (days > 0) {
        return `${days}d ${hours}h`; 
      }
      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      }
      return `${minutes}m ${seconds}s`;
    }
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
  return '';
}


export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidPassword(password: string): boolean {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
  return passwordRegex.test(password);
}

export function splitAndFormatTag(input: string): string[] {
  return input.split(',').map(s => formatTag(s));
}

export function formatTag(input: string): string {
  // Convert to lowercase
  let result = input.toLowerCase();
  // Remove all characters except letters, numbers, and spaces
  result = result.replace(/[^a-z0-9 ]/g, '');
  // Replace multiple spaces with a single space
  result = result.replace(/\s+/g, ' ');
  return result;
}

export function formatRank(value: number): string {
  let suffix = "th";
  if (value % 100 < 11 || value % 100 > 13) {
    switch (value % 10) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
    }
  }
  return `${value}${suffix}`;
}

export function getFileUploadInfo(file: string): { fileType: number, fileName: string, fileExtension: string } {
  let ret = { fileType: 0, fileName: '', fileExtension: '' };
  const extensionIndex = file.lastIndexOf('.');
  if (extensionIndex !== -1) {
    ret.fileExtension = file.substring(extensionIndex + 1).toLowerCase();
    ret.fileName = file.substring(0, extensionIndex);
    switch(ret.fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
        ret.fileType = 1;
        break;
      case 'mp4':
      case 'avi':
        ret.fileType = 2;
        break;
    }
  }
  return ret;
}