import './FundDialog.scss';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material";
import { Bounty, Claim } from "../../types";
import CloseIcon from '@mui/icons-material/Close';

interface FundDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bounty?: Bounty;
  claim?: Claim;
}

export default function FundDialog({ open, setOpen, bounty }: FundDialogProps) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="dialog-title">Add Funds</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {setOpen(false)}}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="claim-dialog">
          <Button>Add Funds</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}