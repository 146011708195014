import { useParams } from 'react-router-dom';
import './BountyPage.scss';
import { getBounty, getBountyClaims } from '../../api';
import { Bounty, Claim, IFile } from '../../types';
import { useEffect, useState } from 'react';
import BountyBar from './BountyBar';
import RankedClaims from './RankedClaims';
import ImageUploader from '../ImageUploader';
import ClaimCreateDialog from './ClaimCreateDialog';

export default function BountyPage() {
  const { id } = useParams();
  const [bounty, setBounty] = useState({} as Bounty);
  const [claims, setClaims] = useState([] as Claim[]);
  const [file, setFile] = useState({} as IFile);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (id) {
      getBounty(id).then(result => {
        setBounty(result.status === 200 ? result.data : {} as Bounty);
      });
      getBountyClaims(id).then(result => {
        setClaims(result.status === 200 ? result.data : []);
      });
    }
  }, [id]);

  /* async function fundClicked() {
    const tokens = Number(fundTokens);
    if (bounty?._id && tokens > 0) {
      const fundResults = await fundBounty(bounty._id, tokens);
      if (fundResults.status !== 200) {
        console.error("fundBounty: " + fundResults.status);
      } else {
        setFundOpen(false);
        setLoadCount(count => count + 1);
      }
    }
  } */

  async function onHandleUpload(status: number, file: IFile) {
    if (status !== 200) {
      console.error("onHandleUpload", status);
    } else {
      console.log("onHandleUpload: ", file);
      setFile(file);
      setDialogOpen(true);
    }
  }

  return (
    <div className='content-page'>
      <BountyBar bounty={bounty} />
      <ImageUploader onUpload={onHandleUpload}>Add Claim</ImageUploader>
      <RankedClaims claims={claims} />
      <ClaimCreateDialog open={dialogOpen} setOpen={setDialogOpen} bounty={bounty} file={file} />
    </div>
  );
};
