import './BountyFeed.scss';
import { useEffect, useState } from "react";
import { Bounty } from "../../types";
import { bountySearch } from "../../api";
import BountyBar from "./BountyBar";
import { useAppContext } from "../../contexts/AppContext";
import BountyGrid from './BountyGrid';

interface BountyFeedProps {
  search: string;
  claimId?: string;
  excludeId?: string;
}

export default function BountyFeed({ search, claimId, excludeId }: BountyFeedProps) {
  const { snackError } = useAppContext();
  const [bounties, setBounties] = useState<Bounty[]>([]);

  useEffect(() => {
    const storedData = claimId ? sessionStorage.getItem("e-" + claimId) : null;
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (Date.now() < parsedData.expiration) {
        setBounties(parsedData.data);
      } else {
        sessionStorage.removeItem("e-" + claimId);
        fetchBounties();
      }
    } else {
      fetchBounties();
    }
  
    function fetchBounties() {
      bountySearch(search, excludeId).then((result) => {
        if (result.status !== 200) {
          snackError("Error loading bounties: " + result.status);
        } else {
          if (claimId) {
            const expirationTime = Date.now() + 5 * 60 * 1000;
            const itemToStore = {
              data: result.data,
              expiration: expirationTime,
            };
            sessionStorage.setItem("e-" + claimId, JSON.stringify(itemToStore));
          }
          setBounties(result.data);
        }
      });
    }
  }, [search, claimId, excludeId]);
  

  return (
    <div className="bounty-feed">
      {bounties.map(bounty => (
        <div className='feed-item' key={bounty._id}>
          <BountyBar bounty={bounty} />
          <BountyGrid bounty={bounty} />
        </div>
      ))}
    </div>
  );
}
